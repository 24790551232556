<template>
  <div>
    <!-- COVER contiene il box introduttivo che può contenere un messaggio promo o i campi di ricerca dell'Homepage -->
    <div class="cover">
      <div class="cover__filter"></div>
      <div class="cover__image"></div>
      <div class="cover__caption">
        <div class="cover__caption__copy">
          <h1>NON SEI ANCORA ABBONATO A UNLIMITED?</h1>
          <a v-if="settings" :href="settings.marketing.contatta_agenti"
                target="_blank" class="cta orange" title="Richiedi subito come fare">Richiedi subito come fare</a>
        </div>
      </div>
    </div>

    <!-- SECTION per gestire le varie sezioni a tutta larghezza - con le classi bg-grey | bg-turquoise | bg-lightBlue si gestisce il background della sezione  -->
    <div class="section">
      <v-container>
        <div class="intro-page">
          <h2>Unlimited</h2>
          <p><strong>UNLIMITED</strong> è il nuovo servizio di <strong>Giuffrè Francis Lefebvre</strong> che permette la consultazione online di oltre 3.000 titoli del nostro catalogo. 
          Una <strong>biblioteca digitale</strong> con all’interno titoli per ogni materia del diritto.</p>
          <p>Grazie ad <strong>UNLIMITED</strong> potrai: </p>
          </div>

        <div class="text-page">
          <ul>
            <li>Fare ricerche all’interno di un <strong>catalogo</strong> ampio e senza restrizioni per materia</li>
            <li>Utilizzare il nuovo <strong>sistema di ricerca</strong> efficace ed intuitivo</li>
            <li>Fruire tutti i contenuti digitali sul <strong>nuovo sfogliatore</strong> con la possibilità di inserire note, evidenziare i contenuti </li>
            <li>Avere <strong>aggiornamenti dei contenuti costanti</strong> attraverso l’inserimento di nuovi titoli consultabili</li>
          </ul>
          <p>Contatta subito il tuo agente di fiducia per iniziare il tuo periodo di prova o contattaci per scoprire come fare.</p>
        </div>
      </v-container>
    </div>

    
  </div>
</template>
<script>
export default {
  props: {
    settings: {
      type: Object
    },
  },
  data: vm => ({
    user: null
  }),
  mounted() {
  }
};
</script>